.hero-banner .hero-banner__slider-slide-container {
  display: flex;
  justify-content: var(--vertical-alignment-desktop);
  align-items: var(--horisontal-alignment-desktop);
  min-height: calc(100vh - var(--header-height));
  height: 100%;
  padding-top: 130px;
  padding-bottom: 130px;
  padding-left: 230px;
  padding-right: 230px;
  flex-direction: column;
}
.hero-banner .hero-banner__slider-button-icon--desktop {
  display: block;
}
.hero-banner .hero-banner__slider-button-icon--mobile {
  display: none;
  opacity: 0.7;
}
.hero-banner .hero-banner__slider-slide {
  height: auto;
  background-color: black;
}
.hero-banner .hero-banner__slider-button-prev {
  left: 105px;
  right: auto;
}
.hero-banner .hero-banner__slider-button-next {
  left: auto;
  right: 105px;
}
.hero-banner .hero-banner__slider-button-prev,
.hero-banner .hero-banner__slider-button-next {
  position: absolute;
  top: 50%;
  z-index: 1;
  display: block;
  padding: 0;
  background-color: transparent;
  transform: translateY(-50%);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}
.hero-banner .hero-banner__slider-button-prev.swiper-button-disabled,
.hero-banner .hero-banner__slider-button-next.swiper-button-disabled {
  opacity: 0.5;
}
.hero-banner .hero-banner__slider-button-prev svg,
.hero-banner .hero-banner__slider-button-next svg {
  width: 20px;
  height: 60px;
  stroke: #ffffff;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .hero-banner .hero-banner__slider-button-prev:not(.swiper-button-disabled):hover,
  .hero-banner .hero-banner__slider-button-next:not(.swiper-button-disabled):hover {
    opacity: 0.7;
  }
}
.hero-banner .hero-banner__slider-button-prev:focus-visible,
.hero-banner .hero-banner__slider-button-next:focus-visible {
  outline: 2px solid #ffffff;
  outline-offset: 3px;
}
.hero-banner .hero-banner__slider-pagination-container {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  padding-left: 230px;
  padding-right: 230px;
}
.hero-banner .hero-banner__slider-pagination-inner-container {
  position: relative;
  display: flex;
  justify-content: center;
}
.hero-banner .hero-banner__slider-pagination {
  position: static;
  display: flex;
}
.hero-banner .hero-banner__slider-pagination .swiper-pagination-bullet {
  opacity: 1;
  width: 12px;
  height: 12px;
  margin-right: 12px;
  border: 2px solid #ffffff;
  background-color: transparent;
  transition-property: background-color, opacity;
  transition-duration: 0.3s, 0.2s;
  transition-timing-function: ease-in-out;
}
.hero-banner .hero-banner__slider-pagination .swiper-pagination-bullet:focus-visible {
  outline: 2px solid #ffffff;
  outline-offset: 3px;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .hero-banner .hero-banner__slider-pagination .swiper-pagination-bullet:hover {
    opacity: 0.7;
  }
}
.hero-banner .hero-banner__slider-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.hero-banner .hero-banner__slider-pagination .swiper-pagination-bullet-active {
  background-color: white;
}
.hero-banner .hero-banner__slider-toggle-video-button {
  position: absolute;
  top: 50%;
  left: 100%;
  display: block;
  margin-left: 20px;
  padding: 0;
  background-color: transparent;
  line-height: 0;
  transform: translateY(-50%);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}
.hero-banner .hero-banner__slider-toggle-video-button:focus-visible {
  outline: 2px solid #ffffff;
  outline-offset: 3px;
}
.hero-banner .hero-banner__slider-toggle-video-button.hidden {
  display: none;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .hero-banner .hero-banner__slider-toggle-video-button:hover {
    opacity: 0.7;
  }
}
.hero-banner .hero-banner__slider:not(.swiper-container-initialized) .hero-banner__slider-toggle-video-button {
  left: 50%;
  margin-left: 0;
  transform: translateX(-50%);
}
.hero-banner .hero-banner__slider:not(.swiper-container-initialized) .hero-banner__slider-button-prev,
.hero-banner .hero-banner__slider:not(.swiper-container-initialized) .hero-banner__slider-button-next {
  display: none;
}
.hero-banner .hero-banner__description,
.hero-banner .hero-banner__description * {
  font-family: "Acumin Pro", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.01em;
  color: #ffffff;
}
.hero-banner .hero-banner__description,
.hero-banner .hero-banner__description strong {
  font-weight: 700;
}
.hero-banner .hero-banner__description {
  max-width: 845px;
  margin-top: 3px;
}
.hero-banner .hero-banner__description *:first-child {
  margin-top: 0;
}
.hero-banner .hero-banner__description p {
  margin-top: 15px;
}
.hero-banner .hero-banner__description a {
  text-decoration: underline;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .hero-banner .hero-banner__description a:hover {
    opacity: 0.7;
  }
}
.hero-banner .hero-banner__content {
  position: relative;
  z-index: 4;
  display: flex;
  align-items: var(--horisontal-alignment-desktop);
  max-width: 1134px;
  text-align: var(--horisontal-alignment-text-desktop);
  flex-direction: column;
}
.hero-banner .hero-banner__content > *:first-child {
  margin-top: 0;
}
.hero-banner .hero-banner__label,
.hero-banner .hero-banner__heading {
  font-family: "GT Super Ds", sans-serif;
  font-weight: 500;
  color: #ffffff;
}
.hero-banner .hero-banner__label {
  margin-bottom: 3px;
  font-size: 20px;
}
.hero-banner .hero-banner__heading {
  font-size: 50px;
  line-height: 1.1;
}
.hero-banner .hero-banner__buttons-container {
  margin-top: 21px;
  margin-bottom: -20px;
  margin-left: -10px;
  margin-right: -10px;
}
.hero-banner .hero-banner__button {
  min-width: 186px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.hero-banner .hero-banner__slider-toggle-video-button-icon {
  display: block;
}
.hero-banner .hero-banner__slider-toggle-video-button-icon svg {
  width: 30px;
  height: 30px;
}
.hero-banner .hero-banner__slider-toggle-video-button-icon.hidden {
  display: none;
}
.hero-banner .hero-banner__image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: var(--image-position-desktop);
}
.hero-banner .hero-banner__image-mobile {
  display: none;
}
.hero-banner .hero-banner__media-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 67.29%, rgba(0, 0, 0, 0.35) 100%);
}
.hero-banner .hero-banner__media-overlay.hero-banner__media-overlay--hidden {
  opacity: 0;
}
.hero-banner .hero-banner__video-container-desktop {
  display: block;
}
.hero-banner .hero-banner__video-container-mobile {
  display: none;
}
.hero-banner .hero-banner__video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.hero-banner .hero-banner__video-external,
.hero-banner .hero-banner__video-external iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.hero-banner .hero-banner__video-poster {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: ease;
}
.hero-banner .hero-banner__video-poster.hidden {
  visibility: hidden;
  opacity: 0;
}
@media (max-width: 1700px) {
  .hero-banner .hero-banner__content {
    max-width: 768px;
  }
}
@media (max-width: 1500px) {
  .hero-banner .hero-banner__slider-slide-container {
    padding-left: 160px;
    padding-right: 160px;
  }
  .hero-banner .hero-banner__slider-pagination-container {
    padding-left: 160px;
    padding-right: 160px;
  }
  .hero-banner .hero-banner__slider-button-prev {
    left: 70px;
  }
  .hero-banner .hero-banner__slider-button-next {
    right: 70px;
  }
}
@media (max-width: 1199px) {
  .hero-banner .hero-banner__content {
    max-width: 575px;
  }
  .hero-banner .hero-banner__slider-slide-container {
    min-height: calc(100vh - var(--header-mobile-height));
    padding-left: 80px;
    padding-right: 80px;
  }
  .hero-banner .hero-banner__slider-pagination-container {
    padding-left: 80px;
    padding-right: 80px;
  }
  .hero-banner .hero-banner__slider-button-prev {
    left: 30px;
  }
  .hero-banner .hero-banner__slider-button-next {
    right: 30px;
  }
}
@media (max-width: 991px) {
  .hero-banner .hero-banner__slider-slide-container {
    padding-left: 55px;
    padding-right: 55px;
  }
  .hero-banner .hero-banner__slider-pagination-container {
    padding-left: 55px;
    padding-right: 55px;
  }
  .hero-banner .hero-banner__description,
  .hero-banner .hero-banner__description * {
    font-size: 16px;
  }
  .hero-banner .hero-banner__description p {
    margin-top: 10px;
  }
  .hero-banner .hero-banner__slider-pagination-inner-container {
    width: 100%;
  }
  .hero-banner .hero-banner__slider-toggle-video-button {
    left: auto;
    right: 0;
  }
  .hero-banner .hero-banner__slider:not(.swiper-container-initialized) .hero-banner__slider-toggle-video-button {
    left: auto;
    transform: translateX(0);
  }
  .hero-banner .hero-banner__slider-button-prev svg,
  .hero-banner .hero-banner__slider-button-next svg {
    width: 15px;
    height: 38px;
  }
  .hero-banner .hero-banner__slider-button-prev {
    left: 20px;
  }
  .hero-banner .hero-banner__slider-button-next {
    right: 20px;
  }
  .hero-banner .hero-banner__video-container-mobile {
    display: block;
  }
  .hero-banner .hero-banner__video-container-desktop {
    display: none;
  }
  .hero-banner .hero-banner__image-mobile {
    display: block;
  }
  .hero-banner .hero-banner__hide-on-mobile {
    display: none;
  }
}
@media (max-width: 767px) {
  .hero-banner .hero-banner__slider-slide-container {
    justify-content: var(--vertical-alignment-mobile);
    align-items: var(--horisontal-alignment-mobile);
    padding-top: 78px;
    padding-bottom: 78px;
  }
  .hero-banner .hero-banner__slider-pagination-container {
    bottom: 50px;
  }
  .hero-banner .hero-banner__slider-pagination .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin-right: 10px;
  }
  .hero-banner .hero-banner__image {
    object-position: var(--image-position-mobile);
  }
  .hero-banner .hero-banner__content {
    align-items: var(--horisontal-alignment-mobile);
    max-width: unset;
    width: 100%;
    text-align: var(--horisontal-alignment-text-mobile);
  }
  .hero-banner .hero-banner__label {
    margin-bottom: 10px;
  }
  .hero-banner .hero-banner__description {
    margin-top: 10px;
  }
  .hero-banner .hero-banner__description,
  .hero-banner .hero-banner__description * {
    font-size: 14px;
    line-height: 1.29;
  }
  .hero-banner .hero-banner__buttons-container {
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .hero-banner .hero-banner__button {
    min-width: 145px;
    margin-bottom: 10px;
  }
  .hero-banner .hero-banner__heading {
    font-size: 40px;
    line-height: 1.2;
  }
}
@media (max-width: 575px) {
  .hero-banner .hero-banner__slider-slide-container {
    padding-left: 38px;
    padding-right: 38px;
  }
  .hero-banner .hero-banner__slider-pagination-container {
    padding-left: 38px;
    padding-right: 38px;
  }
  .hero-banner .hero-banner__slider-button-icon--desktop {
    display: none;
  }
  .hero-banner .hero-banner__slider-button-icon--mobile {
    display: block;
  }
  .hero-banner .hero-banner__slider-button-prev,
  .hero-banner .hero-banner__slider-button-next {
    padding: 10px;
  }
  .hero-banner .hero-banner__slider-button-prev svg,
  .hero-banner .hero-banner__slider-button-next svg {
    width: 8px;
    height: 22px;
  }
  .hero-banner .hero-banner__slider-button-prev {
    left: 5px;
  }
  .hero-banner .hero-banner__slider-button-next {
    right: 5px;
  }
  .hero-banner .hero-banner__heading {
    font-size: 25px;
    line-height: 1.1;
  }
  .hero-banner .hero-banner__buttons-container {
    display: flex;
    align-items: inherit;
    margin-top: 23px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }
  .hero-banner .hero-banner__button {
    margin-left: 0;
    margin-right: 0;
  }
  .hero-banner .hero-banner__button:last-child {
    margin-bottom: 0;
  }
  .hero-banner .hero-banner__description,
  .hero-banner .hero-banner__description * {
    margin-top: 10px;
    font-size: 13px;
    line-height: 1.1;
  }
}
@media (max-width: 480px) {
  .hero-banner .hero-banner__label {
    margin-bottom: 14px;
  }
  .hero-banner .hero-banner__description,
  .hero-banner .hero-banner__description * {
    margin-top: 14px;
  }
}